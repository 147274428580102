import { alpha, Box, makeStyles, Theme, useTheme } from '@material-ui/core';
import { Icon } from '@iconify/react';

const useStyles = makeStyles<Theme, { color: string }>((theme) => ({
  root: {
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

type TendingIconProps = {
  tending: number;
  inverse?: boolean;
};

export function TendingIcon({ tending, inverse = false }: TendingIconProps) {
  const theme = useTheme();

  const getIconAndColor = (tending: number, inverse: boolean) => {
    const isPositive = tending > 0;
    const isNegative = tending < 0;

    const icon =
      tending === 0
        ? 'eva:arrow-forward-fill'
        : tending > 0
        ? 'eva:trending-up-fill'
        : 'eva:trending-down-fill';

    const iconColor = isPositive
      ? inverse
        ? theme.palette.error.main
        : theme.palette.success.main
      : isNegative
      ? inverse
        ? theme.palette.success.main
        : theme.palette.error.main
      : theme.palette.warning.main;

    return { icon, iconColor };
  };

  const { icon, iconColor } = getIconAndColor(tending, inverse);

  const classes = useStyles({ color: iconColor });

  return (
    <Box
      className={classes.root}
      sx={{ backgroundColor: alpha(iconColor, 0.16) }}
    >
      <Icon width={16} height={16} icon={icon} color={iconColor} />
    </Box>
  );
}
