import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { firebaseReducer } from 'react-redux-firebase';
// slices
import settingsReducer from './slices/settings';
import authJwtReducer from './slices/authJwt';
import userReducer from './slices/user';
import menuReducer from './slices/menu';

// import shopIdRevenueReducer from './slices/shopIdRevenue';

import chartApiReducer from './slices/chartApi';

// import countryListReducer from './slices/countryList';
import topSellerReducer from './slices/topSeller';
import selectProductReducer from './slices/selectProduct';

import configReducer from './slices/config';
import channelProductsReducer from './slices/channelProducts';

import ordersReducer from './slices/orders';
import productItemsReducer from './slices/productItems';
import notificationsStateReducer from './slices/notifications';
import pricesBySourceReducer from './slices/pricesBySource';
import apiStatusReducer from './slices/apiStatus';
import articlesDetailsReducer from './slices/articlesDetail';
import revenueAndExpensesReducer from './slices/revenueAndExpenses';
import revenueAndOrdersReducer from './slices/revenueAndOrders';
import PriceChangesReducer from './slices/priceChanges';
import productHealthReducer from './slices/productHealth';
import ProfitReducer from './slices/profit';
import profitListReducer from './slices/profitList';
import dashboardTabReducer from './slices/dashboardTab';
import ordersTabReducer from './slices/ordersTab';
import stockHealthReducer from './slices/stockHealth';
import stockOverviewReducer from './slices/stockOverview';
import categoriesReducer from './slices/categories';
import returnsByProductReducer from './slices/returnsByProduct';
import marginByProductReducer from './slices/marginByProduct';
import ordersByStatusReducer from './slices/ordersByStatus';
import revenueByB2bB2cReducer from './slices/revenueByB2bB2c';
import averageOrderValueReducer from './slices/averageOrderValue';
import marketplaceReducer from './slices/marketplace';
import ordersByCountryReducer from './slices/ordersByCountry';
import ordersByChannelReducer from './slices/ordersByChannel';
import filtersReducer from './slices/filters';
import salesByCountryReducer from './slices/salesByCountry';
import activityStreamReducer from './slices/activityStream';
import logFilesReducer from './slices/logFiles';
import onlineMarketingReducer from './slices/onlineMarketing';
import orderReducer from './slices/order';
import stockManagementReducer from './slices/stockManagement';
import purchaseReducer from './slices/purchase';
import demandPlanningReducer from './slices/demandPlanning';
import purchaseDetailReducer from './slices/purchaseDetail';
import purchaseArticlesReducer from './slices/purchaseArticles';
import articleVariantsReducer from './slices/articleVariants';
import orderReturnsByChannelReducer from './slices/orderReturnsByChannel';
import bundleReducer from './slices/bundle';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const authPersistConfig = {
  key: 'authJwt',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated']
};

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  settings: settingsReducer,
  user: userReducer,
  menuTree: menuReducer,
  chartApi: chartApiReducer,
  topSeller: topSellerReducer,
  selectedProduct: selectProductReducer,
  channelProducts: channelProductsReducer,
  config: configReducer,
  notificationsState: notificationsStateReducer,
  orders: ordersReducer,
  productItems: productItemsReducer,
  pricesBySource: pricesBySourceReducer,
  apiStatus: apiStatusReducer,
  articlesDetails: articlesDetailsReducer,
  revenueAndExpenses: revenueAndExpensesReducer,
  revenueAndOrders: revenueAndOrdersReducer,
  PriceChanges: PriceChangesReducer,
  productHealth: productHealthReducer,
  profit: ProfitReducer,
  profitList: profitListReducer,
  dashboardTab: dashboardTabReducer,
  ordersTab: ordersTabReducer,
  stockHealth: stockHealthReducer,
  stockOverview: stockOverviewReducer,
  categories: categoriesReducer,
  returnsByProduct: returnsByProductReducer,
  marginByProduct: marginByProductReducer,
  ordersByStatus: ordersByStatusReducer,
  revenueByB2bB2c: revenueByB2bB2cReducer,
  averageOrderValue: averageOrderValueReducer,
  marketplace: marketplaceReducer,
  ordersByCountry: ordersByCountryReducer,
  ordersByChannel: ordersByChannelReducer,
  filters: filtersReducer,
  salesByCountry: salesByCountryReducer,
  bundle: bundleReducer,
  activityStream: activityStreamReducer,
  logFiles: logFilesReducer,
  onlineMarketing: onlineMarketingReducer,
  order: orderReducer,
  stockManagement: stockManagementReducer,
  purchase: purchaseReducer,
  demandPlanning: demandPlanningReducer,
  purchaseDetail: purchaseDetailReducer,
  purchaseArticles: purchaseArticlesReducer,
  articleVariants: articleVariantsReducer,
  orderReturnsByChannel: orderReturnsByChannelReducer,
  authJwt: persistReducer(authPersistConfig, authJwtReducer)
});

export type RootState = ReturnType<typeof rootReducer>;

export { rootPersistConfig, rootReducer };
