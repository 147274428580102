import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import axios from '../../utils/axios';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { OrderReturnsByChannelState } from '../../@types/reduxStates';
import { OrderReturnsByChannelResponse } from '../../@types/responsesAPI';

const initialState: OrderReturnsByChannelState = {
  params: '',
  APIStatus: APIStatus.IDLE,
  response: {
    rows: [],
    total: {
      source: '',
      orders: 0,
      returnOrders: 0,
      returnOrdersVolume: 0,
      ordersVolume: 0,
      tending: 0,
      returnRatio: 0
    },
    totalCount: 0
  }
};

const slice = createSlice({
  name: 'orderReturnsByChannel',
  initialState,
  reducers: {
    setParams: (
      state: OrderReturnsByChannelState,
      action: PayloadAction<string>
    ) => {
      state.params = action.payload;
    },
    getOrderReturnsByChannel: (state: OrderReturnsByChannelState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getOrderReturnsByChannelSuccess: (
      state: OrderReturnsByChannelState,
      action: PayloadAction<OrderReturnsByChannelResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
    },
    getOrderReturnsByChannelError: (
      state: OrderReturnsByChannelState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchOrderReturnsByChannel(
  params: URLSearchParams,
  abortController: AbortController
) {
  return async (dispatch: any) => {
    dispatch(setParams(params.toString()));
    try {
      dispatch(getOrderReturnsByChannel());
      const response: AxiosResponse = await axios.get(
        '/api/v1/orderReturnsByChannel',
        {
          signal: abortController.signal,
          params: Object.fromEntries(params.entries())
        }
      );
      dispatch(getOrderReturnsByChannelSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(getOrderReturnsByChannelError(error as APIError));
    }
  };
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setParams,
  getOrderReturnsByChannel,
  getOrderReturnsByChannelError,
  getOrderReturnsByChannelSuccess
} = slice.actions;
