import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkDispatch
} from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { Dispatch } from 'react';
import axios from '../../utils/axios';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { OrdersTabState, Pagination } from '../../@types/reduxStates';
import {
  AreaChartFiltersState,
  OrderFiltersState
} from '../../@types/filterBars';
import {
  ByChannelReponse,
  OrderListResponse,
  OrdersTabResponse,
  RevenueByCountryResponse
} from '../../@types/responsesAPI';
import { OrderRow } from '../../@types/tableRows';
import { sortByProperty } from '../../utils/sortValues';
import { HeaderTableFilter, SortBy } from '../../@types/filters';

const initialState: OrdersTabState = {
  data: {
    revenueByCountry: {
      filters: {
        timePeriod: {
          startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
          endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
        },
        sourceId: 0,
        shopId: 0,
        countryId: 0,
        customerId: 0,
        profit: 0
      },
      APIStatus: APIStatus.IDLE,
      data: []
    },
    revenueByChannel: {
      filters: {
        timePeriod: {
          startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
          endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
        },
        shopId: 0,
        sourceId: 0,
        countryId: 0,
        customerId: 0,
        profit: 0
      },
      data: [
        {
          name: '',
          margin: 0,
          marginBefore: 0,
          marginTending: 0,
          marginPercent: 0,
          revenue: 0,
          revenueBefore: 0,
          revenuePercent: 0,
          revenueTending: 0
        }
      ],
      APIStatus: APIStatus.IDLE
    },
    orderList: {
      filters: {
        timePeriod: {
          startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
          endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
        },
        amount: [-10000, 10000],
        margin: [-1000, 10000],
        countryId: 0,
        searchOrders: '',
        status: [],
        sourceChannels: [],
        customerList: [],
        orderType: [],
        mandantShops: []
      },
      response: {
        rows: [],
        total: {
          amountTotal: 0,
          amountTotalNet: 0,
          itemsTotal: 0,
          marginTotal: 0
        },
        totalCount: {
          count: 0
        }
      },
      APIStatus: APIStatus.IDLE
    }
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'ordersTab',
  initialState,
  reducers: {
    resetOrdersTab: (state: OrdersTabState) => {
      state.APIStatus = APIStatus.IDLE;
    },
    getOrdersTab: (state: OrdersTabState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getOrdersTabSuccess: (
      state: OrdersTabState,
      action: PayloadAction<OrdersTabResponse>
    ) => {
      state.data.revenueByCountry.data = action.payload.revenueByCountry;
      state.data.revenueByChannel.data = action.payload.revenueByChannel;
      state.data.orderList.response = action.payload.orderList;
      state.APIStatus = APIStatus.FULFILLED;
    },
    getOrdersTabError: (
      state: OrdersTabState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    },

    // RevenueByCountry
    setRevenueByCountry: (
      state: OrdersTabState,
      action: PayloadAction<RevenueByCountryResponse>
    ) => {
      state.data.revenueByCountry.data = action.payload;
    },
    setRevenueByCountryFilters: (
      state: OrdersTabState,
      action: PayloadAction<AreaChartFiltersState>
    ) => {
      state.data.revenueByCountry.filters = action.payload;
    },
    getRevenueByCountry: (state: OrdersTabState) => {
      state.data.revenueByCountry.APIStatus = APIStatus.PENDING;
      state.data.revenueByCountry.error = undefined;
    },
    getRevenueByCountrySuccess: (
      state: OrdersTabState,
      action: PayloadAction<RevenueByCountryResponse>
    ) => {
      state.data.revenueByCountry.APIStatus = APIStatus.FULFILLED;
      state.data.revenueByCountry.data = action.payload;
    },
    getRevenueByCountryError: (
      state: OrdersTabState,
      action: PayloadAction<APIError>
    ) => {
      state.data.revenueByCountry.APIStatus = APIStatus.REJECTED;
      state.data.revenueByCountry.error = action.payload;
    },

    // RevenueByChannel

    setRevenueByChannel: (
      state: OrdersTabState,
      action: PayloadAction<ByChannelReponse>
    ) => {
      state.data.revenueByChannel.data = action.payload;
    },
    setRevenueByChannelFilters: (
      state: OrdersTabState,
      action: PayloadAction<AreaChartFiltersState>
    ) => {
      state.data.revenueByChannel.filters = action.payload;
    },
    getRevenueByChannel: (state: OrdersTabState) => {
      state.data.revenueByChannel.APIStatus = APIStatus.PENDING;
      state.data.revenueByChannel.error = undefined;
    },
    getRevenueByChannelSuccess: (
      state: OrdersTabState,
      action: PayloadAction<ByChannelReponse>
    ) => {
      state.data.revenueByChannel.APIStatus = APIStatus.FULFILLED;
      state.data.revenueByChannel.data = action.payload;
    },
    getRevenueByChannelError: (
      state: OrdersTabState,
      action: PayloadAction<APIError>
    ) => {
      state.data.revenueByChannel.APIStatus = APIStatus.REJECTED;
      state.data.revenueByChannel.error = action.payload;
    },

    // OrderList
    setOrderList(
      state: OrdersTabState,
      action: PayloadAction<Array<OrderRow>>
    ) {
      state.data.orderList.response.rows = action.payload;
    },
    setOrderListFilters: (
      state: OrdersTabState,
      action: PayloadAction<OrderFiltersState>
    ) => {
      state.data.orderList.filters = action.payload;
    },
    getOrderList: (state: OrdersTabState) => {
      state.data.orderList.error = undefined;
      state.data.orderList.APIStatus = APIStatus.PENDING;
    },
    getOrderListSuccess: (
      state: OrdersTabState,
      action: PayloadAction<OrderListResponse>
    ) => {
      state.data.orderList.response = action.payload;
      state.data.orderList.APIStatus = APIStatus.FULFILLED;
    },
    getOrderListError: (
      state: OrdersTabState,
      action: PayloadAction<APIError>
    ) => {
      state.data.orderList.error = action.payload;
      state.data.orderList.APIStatus = APIStatus.REJECTED;
    }
  }
});

export function fetchOrdersTab(articleId: number, lang: string) {
  return async (dispatch: any) => {
    try {
      const params: {
        idArticle: number;
        from: string;
        till: string;
        lang: string;
      } = {
        idArticle: articleId,
        from: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
        till: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
        lang
      };
      dispatch(getOrdersTab());
      const response = await axios.get('/rest/v1/article/ordersList', {
        params
      });
      dispatch(getOrdersTabSuccess(response.data));
    } catch (error) {
      dispatch(getOrdersTabError(error as APIError));
    }
  };
}

export function fetchRevenueByCountry(
  filters: AreaChartFiltersState,
  abortController: AbortController,
  articleId: number = 0
) {
  return async (dispatch: any) => {
    dispatch(setRevenueByCountryFilters(filters));
    try {
      dispatch(getRevenueByCountry());
      let apiUrl = '/api/v2/dashboard/revenueByCountry';
      if (articleId) {
        apiUrl = '/api/v2/article/revenueByCountry';
      }
      const response = await axios.get(apiUrl, {
        signal: abortController.signal,
        params: {
          idArticle: articleId > 0 ? articleId : undefined,
          from: filters.timePeriod.startFilter,
          till: filters.timePeriod.endFilter,
          shopId: filters.shopId,
          sourceId: filters.sourceId,
          customerId: filters.customerId ? filters.customerId : undefined
        }
      });
      dispatch(getRevenueByCountrySuccess(response.data));
    } catch (error) {
      dispatch(getRevenueByCountryError(error as APIError));
    }
  };
}

export function fetchRevenueByChannel(
  filters: AreaChartFiltersState,

  idArticle: number = 0
) {
  return async (dispatch: any) => {
    dispatch(setRevenueByChannelFilters(filters));
    try {
      dispatch(getRevenueByChannel());
      const response = await axios.get('/api/v1/dashboard/revenueByChannel', {
        params: {
          from: filters.timePeriod.startFilter,
          till: filters.timePeriod.endFilter,
          shop: filters.shopId > 0 ? filters.shopId : undefined,
          source: filters.sourceId > 0 ? filters.sourceId : undefined,
          country: filters.countryId > 0 ? filters.countryId : undefined,
          idArticle: idArticle > 0 ? idArticle : undefined
        }
      });
      dispatch(getRevenueByChannelSuccess(response.data));
    } catch (error) {
      dispatch(getRevenueByChannelError(error as APIError));
    }
  };
}

export const fetchOrderListData = async (
  filters: OrderFiltersState,
  { skip, limit }: Pagination,
  { by, order }: SortBy,
  abortController: AbortController,
  route: string = '/api/v2/orderList',
  idArticle?: string
) => {
  const params = {
    idArticle,
    skip,
    limit,
    by,
    order,
    from: filters.timePeriod.startFilter,
    till: filters.timePeriod.endFilter,
    status:
      filters.status.length > 0
        ? filters.status.map((t) => `${t.id}`).join(',')
        : undefined,
    sourceIds:
      filters.sourceChannels.length > 0
        ? filters.sourceChannels.map((t) => `${t.id}`).join(',')
        : undefined,
    customers:
      filters.customerList.length > 0
        ? filters.customerList.map((c) => c.id).join(',')
        : undefined,
    mandantShops:
      filters.mandantShops.length > 0
        ? filters.mandantShops.map((c) => c.id).join(',')
        : undefined,
    orderType:
      filters.orderType.length > 0
        ? filters.orderType.map((c) => c.id).join(',')
        : undefined,
    // amountFrom: filters.amount[0],
    // amountTo: filters.amount[1],
    // marginFrom: filters.margin[0],
    // marginTo: filters.margin[1],
    countryId: filters.countryId,
    searchOrders:
      filters.searchOrders.length > 0 ? filters.searchOrders : undefined
  };

  const response = await axios.get(route, {
    signal: abortController.signal,
    params
  });

  return response.data;
};

export function fetchOrderList(
  filters: OrderFiltersState,
  { skip, limit }: Pagination,
  { by, order }: SortBy,
  abortController: AbortController,
  route: string = '/api/v2/orderList',
  idArticle?: string
) {
  return async (dispatch: any) => {
    dispatch(setOrderListFilters(filters));
    dispatch(getOrderList());
    try {
      const data = await fetchOrderListData(
        filters,
        { skip, limit },
        { by, order },
        abortController,
        route,
        idArticle
      );
      dispatch(getOrderListSuccess(data));
    } catch (error) {
      dispatch(getOrderListError(error as APIError));
    }
  };
}

export function sortRevenueByCountryBy(
  list: any,
  orderBy: string,
  order: 'asc' | 'desc'
) {
  const orderedList = sortByProperty(list, orderBy, order);
  return (
    dispatch: ThunkDispatch<any, null, AnyAction> &
      ThunkDispatch<any, undefined, AnyAction> &
      Dispatch<any>
  ) => dispatch(setRevenueByCountry(orderedList));
}

export function sortOrdersBy(
  list: Array<OrderRow>,
  orderBy: string,
  order: 'asc' | 'desc',
  orderType: HeaderTableFilter
) {
  const orderedList = sortByProperty(list, orderBy, order, orderType);
  return (
    dispatch: ThunkDispatch<any, null, AnyAction> &
      ThunkDispatch<any, undefined, AnyAction> &
      Dispatch<any>
  ) => dispatch(setOrderList(orderedList));
}

export default slice.reducer;

export const {
  resetOrdersTab,
  getOrdersTab,
  getOrdersTabSuccess,
  getOrdersTabError,

  setRevenueByCountry,
  getRevenueByCountry,
  getRevenueByCountrySuccess,
  getRevenueByCountryError,
  setRevenueByCountryFilters,

  setRevenueByChannel,
  getRevenueByChannel,
  getRevenueByChannelSuccess,
  getRevenueByChannelError,
  setRevenueByChannelFilters,

  setOrderList,
  getOrderList,
  getOrderListSuccess,
  getOrderListError,
  setOrderListFilters
} = slice.actions;
