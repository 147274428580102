import { useTranslation } from 'react-i18next';
import MultiselectDefault from './MultiselectDefault';
import { MultiselectItem } from '../../../@types/filters';
import useOrdersTab from '../../../hooks/useOrdersTab';

type Props = {
  valueSelected: Array<MultiselectItem>;
  onChangeValue: (value: Array<MultiselectItem>) => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
};

function MultiselectOrderType({
  valueSelected,
  onChangeValue,
  label = 'filter:order_type',
  placeholder = 'filter:select_order_type',
  disabled = false
}: Props) {
  const { t } = useTranslation(['filter']);

  const { orderType } = useOrdersTab();

  return (
    <MultiselectDefault
      disabled={disabled}
      label={t(label)}
      placeholder={disabled ? '' : t(placeholder)}
      valueSelected={valueSelected}
      options={orderType}
      onChange={onChangeValue}
    />
  );
}

export default MultiselectOrderType;
