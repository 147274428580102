import {
  DateRangeFilter,
  MultiselectItem,
  MultiselectTreeItem
} from '../@types/filters';

export const varToString = (varObj: any) => Object.keys(varObj)[0];

export const getKeyValue = <T, K extends keyof T>(obj: T, key: K): T[K] =>
  obj[key];

export function debounce<A = unknown, R = void>(
  fn: (args: A) => R,
  ms: number
): [(args: A) => Promise<R>, () => void] {
  let timer: NodeJS.Timeout;

  const debouncedFunc = (args: A): Promise<R> =>
    new Promise((resolve) => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        resolve(fn(args));
      }, ms);
    });

  const teardown = () => clearTimeout(timer);

  return [debouncedFunc, teardown];
}

export function getPercentage(value: number, maxValue: number): number {
  if (maxValue === 0) {
    return 0;
  }
  const res = (value / maxValue) * 100;
  return res;
}

export function getPercentageDifference(
  revenue: number,
  expenses: number
): number {
  const difference = revenue - expenses;
  return (difference / revenue) * 100;
}

export function getTendingPercentage(
  value: number,
  previousValue: number
): number {
  if (previousValue === 0) {
    return 0;
  }
  const difference = value - previousValue;
  return difference / previousValue;
}

export function getPercentageDiff(dataOne: number, dataTwo: number): number {
  if (dataOne === 0 || dataTwo === 0) {
    return 0;
  }
  // return (1 - dataOne / dataTwo) * 100;
  // const difference =  100 * Math.abs( (dataOne - dataTwo) / ( (dataOne  + dataTwo) / 2 ) );
  // const difference =  Math.abs(100 - dataTwo / dataOne * 100)//.toFixed(10);
  const difference = Math.abs(100 - (100 * dataOne) / (dataOne + dataTwo));
  return difference;
}

export function calculatePercentageDifference(
  oldValue: number,
  newValue: number
): number {
  if (oldValue === 0) {
    return 0;
  }

  const absoluteDifference = newValue - oldValue;
  const percentageDifference = absoluteDifference / Math.abs(oldValue);

  return percentageDifference;
}

export function getDifferenceInPercentage(
  dataOne: number,
  dataTwo: number
): number {
  if (dataOne === 0 || dataTwo === 0) {
    return 0;
  }
  return Math.abs(100 - (100 * dataOne) / (dataOne + dataTwo));
}

export function divideIfNotZero(
  numerator: number,
  denominator: number
): number {
  if (denominator === 0 || isNaN(denominator)) {
    return 0;
  }
  return numerator / denominator;
}

export function capitalizeFirstLetter(name?: string) {
  return name && name.length > 0 ? name[0].toUpperCase() + name.slice(1) : '';
}

export function stringToNumber(numberString: string = ''): number {
  if (stringIsNumber(numberString)) {
    return Number(numberString);
  }
  return 0;
}

export function stringIsNumber(numberString: string = ''): boolean {
  return (
    numberString.length > 0 &&
    !Number.isNaN(Number(numberString)) &&
    Number.isFinite(Number(numberString))
  );
}

export function unknownToNumber(data: unknown): number {
  if (typeof data === 'number') {
    return data;
  }
  if (typeof data === 'string' && stringIsNumber(data)) {
    return Number(data);
  }
  return 0;
}

export function limitMinMax(
  numero: number,
  minimo: number,
  maximo: number
): number {
  if (numero < minimo) {
    return minimo;
  }
  if (numero > maximo) {
    return maximo;
  }
  return numero;
}

export function getMonthName(monthNum: any, locale: string): string {
  if (monthNum > -1 && monthNum < 12) {
    const monthName = new Intl.DateTimeFormat(locale, { month: 'long' }).format(
      new Date(2000, monthNum, 1)
    );
    return monthName.charAt(0).toUpperCase() + monthName.slice(1);
  }
  return '';
}

export function formatMonthYear(date: Date, locale: string): string {
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    year: 'numeric'
  };
  const formattedDate: string = date.toLocaleString(locale, options);
  return formattedDate;
}

export function fromStringToDate(date: string): Date {
  const partsDate = date.split('-');
  const day = parseInt(partsDate[0], 10);
  const month = parseInt(partsDate[1], 10) - 1;
  const year = parseInt(partsDate[2], 10);

  return new Date(year, month, day);
}

export function getDaysOfDifference(
  startDate: string,
  endDate: string
): number {
  const sDate = getDateOrToday(startDate);
  const eDate = getDateOrToday(endDate);
  const millis = sDate.getTime() - eDate.getTime();
  const days = Math.floor(millis / (1000 * 60 * 60 * 24));
  return Math.abs(days);
}

function getDateOrToday(dateString: string): Date {
  if (!dateString || typeof dateString !== 'string') {
    return new Date();
  }
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return new Date();
  }
  return date;
}

export function formatTableDate(dateString: string, locale: string): string {
  if (!dateString || typeof dateString !== 'string') {
    return '-';
  }
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return '-';
  }
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  };
  return date.toLocaleDateString(locale, options);
  // return format(date, 'dd-MM-yyyy', { locale: locale === 'de-DE' ? de : enGB });
}

export function coerceAtMost(value: number, maxValue: number): number {
  return Math.min(value, maxValue);
}

export function coerceAtLeast(value: number, minValue: number): number {
  return Math.max(value, minValue);
}

export function decodeUrlTextParam(value: string): string {
  const noSpaces = value.replace(/\+/g, ' ');
  return decodeURIComponent(noSpaces);
}

export function createFilterURL(
  value: string,
  options: (MultiselectItem | MultiselectTreeItem)[]
): (MultiselectItem | MultiselectTreeItem)[] {
  return value
    .split('-')
    .map((id) => options.find((s) => s.id === Number(id)))
    .filter(
      (so): so is MultiselectItem | MultiselectTreeItem => so !== undefined
    );
}

export function areListsEqual(listA: any[], listB: any[]): boolean {
  if (listA.length !== listB.length) return false;

  return listA.every((itemA, index) => {
    const itemB = listB[index];

    return JSON.stringify(itemA) === JSON.stringify(itemB);
  });
}

export function formatDateRange(filter: DateRangeFilter): string {
  return `from=${filter.startFilter}&till=${filter.endFilter}`;
}

export function calculateSummaryPage<T extends Record<string, any>>(
  list: T[],
  property: keyof T,
  operation: 'sum' | 'avg'
): number {
  const values = list
    .map((item) => Number(item[property]))
    .filter((value) => !isNaN(value));

  if (values.length === 0) {
    return 0;
  }

  if (operation === 'sum') {
    return values.reduce((acc, curr) => acc + curr, 0);
  }
  if (operation === 'avg') {
    return values.reduce((acc, curr) => acc + curr, 0) / values.length;
  }
  return 0;
}
