import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MultiselectDefault from './MultiselectDefault';
import useFilters from '../../../hooks/useFilters';
import { MultiselectItem } from '../../../@types/filters';
import { APIStatus } from '../../../@types/APIStatus';

type Props = {
  valueSelected: Array<MultiselectItem>;
  onChangeValue: (value: Array<MultiselectItem>) => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
};

function MultiselectCustomers({
  valueSelected,
  onChangeValue,
  label = 'filter:customer_class',
  placeholder = 'filter:select_customer',
  disabled = false
}: Props) {
  const { t } = useTranslation(['component', 'filter']);
  const {
    filtersStatus: { customers: status },
    getCustomers,
    response: { customers: data }
  } = useFilters();

  useEffect(() => {
    if (status === APIStatus.IDLE || status === APIStatus.REJECTED) {
      getCustomers();
    }
  }, []);

  return (
    <MultiselectDefault
      disabled={disabled}
      label={t(label)}
      placeholder={t(placeholder)}
      valueSelected={valueSelected}
      options={data}
      onChange={onChangeValue}
    />
  );
}

export default MultiselectCustomers;
