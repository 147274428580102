import axios from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create();
// axiosInstance.defaults.timeout = 50

const redirectToLogin = () => {
  const loginUrl = `${window.location.protocol}//${window.location.host}/auth/login`;
  window.location.href = loginUrl;
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { status, data } = error.response;
      const isAuthPage = window.location.href.includes('/auth/');
      switch (error.response.status) {
        case 400: {
          console.log(data);
          break;
        } // Bad request data
        case 401:
        case 403: {
          if (!isAuthPage) {
            redirectToLogin();
          }
          break;
        }
        case 500: {
          if (!isAuthPage) {
            redirectToLogin();
          }
          break;
        }
        default: {
          return Promise.reject(data || 'Something went wrong');
        }
      }
      return Promise.reject(
        (error.response && error.response.data) || 'Something went wrong'
      );
    }
    const errorDetails = {
      code: 0,
      status: error.code || '',
      message: error.message || 'Something went wrong'
    };

    switch (error.code) {
      case 'ECONNABORTED': // Timeout
        errorDetails.message = 'Request timeout';
        break;
      case 'ERR_CANCELED': // Cancelled
        errorDetails.message = `Request canceled: ${error.config?.url}`;
        break;
      default:
        errorDetails.message = error.message;
        break;
    }
    console.error(`Axios error: ${error.message}`);
    return Promise.reject(errorDetails);
  }
);

export default axiosInstance;
