import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { ProfitListFilterBarState } from '../../@types/filterBars';
import { SortBy } from '../../@types/filters';
import { Pagination, ProfitListState } from '../../@types/reduxStates';
import { ProfitListReponse } from '../../@types/responsesAPI';
import axios from '../../utils/axios';

const initialState: ProfitListState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    compare: false,
    compareTo: {
      startFilter: format(subDays(new Date(), 60), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 31), 'yyyy-MM-dd')
    },
    timeInterval: 0,
    groupBy: 0,
    stock: 0,
    mandantShops: [],
    profitBy: 0,
    countries: [],
    sourceChannels: [],
    orderStatus: [],
    categories: [],
    searchName: '',
    columns: [],
    customerList: [],
    groupByCategory: 0,
    suppliers: [],
    manufactures: [],
    attributes: []
    /* filtersConfigSelected: '0',
    by: 'revenueNetto',
    order: 'desc',
    page: 0,
    rowsPerPage: 10 */
  },
  lang: 'en-GB',
  response: {
    rows: [],
    total: {
      unitsSold: 0,
      revenueBrutto: 0,
      revenueNetto: 0,
      revenueShare: 0,
      discount: 0,
      cancellations: 0,
      netAfterCancellations: 0,
      grossAfterCancellations: 0,
      returns: 0,
      unitsReturn: 0,
      returnsRate: 0,
      shippingCosts: 0,
      operatingCosts: 0,
      transportCosts: 0,
      customs: 0,
      creditPayout: 0,
      netAfterCreditPayout: 0,
      bruttoAfterCreditPayout: 0,
      productCosts: 0,
      db1: 0,
      db1Rate: 0,
      storageCosts: 0,
      paymentFees: 0,
      db2: 0,
      db2Rate: 0,
      advertisingCosts: 0,
      marketplaceFees: 0,
      db3: 0,
      db3Rate: 0,
      currentStock: 0
    },
    totalCount: { count: 0 }
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'profitList',
  initialState,
  reducers: {
    setFilters: (
      state: any,
      action: PayloadAction<ProfitListFilterBarState>
    ) => {
      state.filters = action.payload;
    },
    getProfitList: (state: any) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getProfitListSuccess: (
      state: any,
      action: PayloadAction<ProfitListReponse>
    ) => {
      state.response = action.payload;
      state.APIStatus = APIStatus.FULFILLED;
      state.error = undefined;
    },
    getProfitListError: (state: any, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function updateProfitListFilterBarState(
  filterBar: ProfitListFilterBarState
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filterBar));
  };
}

export const fetchProfitListData = async (
  filters: ProfitListFilterBarState,
  { skip, limit }: Pagination,
  { by, order }: SortBy,
  lang: string,
  signal: AbortSignal
) => {
  const params = {
    lang,
    skip,
    limit,
    by,
    order,
    from: filters.timePeriod.startFilter,
    till: filters.timePeriod.endFilter,
    compare: filters.compare,
    compareFrom: filters.compareTo.startFilter,
    compareTill: filters.compareTo.endFilter,
    timeInterval: filters.timeInterval > 0 ? filters.timeInterval : undefined,
    groupBy: filters.groupBy,
    groupByCategory: filters.groupByCategory,
    stock: filters.stock,
    profitBy: filters.profitBy,
    countries:
      filters.countries.length > 0
        ? filters.countries.map((c) => c.id).join(',')
        : undefined,
    customers:
      filters.customerList.length > 0
        ? filters.customerList.map((c) => c.id).join(',')
        : undefined,
    sources:
      filters.sourceChannels.length > 0
        ? filters.sourceChannels.map((c) => c.id).join(',')
        : undefined,
    status:
      filters.orderStatus.length > 0
        ? filters.orderStatus.map((st) => st.id).join(',')
        : undefined,
    category:
      filters.categories.length > 0
        ? filters.categories.map((c) => c.categoryExtId).join(',')
        : undefined,
    manufacturers:
      filters.manufactures.length > 0
        ? filters.manufactures.map((c) => c.id).join(',')
        : undefined,
    searchName: filters.searchName.length > 0 ? filters.searchName : undefined,
    mandantShops:
      filters.mandantShops.length > 0
        ? filters.mandantShops.map((c) => c.id).join(',')
        : undefined,
    suppliers:
      filters.suppliers.length > 0
        ? filters.suppliers.map((c) => c.id).join(',')
        : undefined,
    attributes:
      filters.attributes.length > 0
        ? filters.attributes.map((c) => `${c.group}=${c.value}`).join(',')
        : undefined
  };

  const response = await axios.get('/api/v2/profit/list', {
    signal,
    params
  });

  return response.data;
};

export function fetchProfitList(
  filters: ProfitListFilterBarState,
  { skip, limit }: Pagination,
  { by, order }: SortBy,
  lang: string,
  abortController: AbortController
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filters));
    dispatch(getProfitList());
    try {
      const data = await fetchProfitListData(
        filters,
        { skip, limit },
        { by, order },
        lang,
        abortController.signal
      );
      dispatch(getProfitListSuccess(data));
    } catch (error) {
      dispatch(getProfitListError(error as APIError));
    }
  };
}

export const {
  setFilters,
  getProfitList,
  getProfitListError,
  getProfitListSuccess
} = slice.actions;

export default slice.reducer;
