import {
  makeStyles,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Theme
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SummaryHeaderCell from '../tables/SummaryHeaderCell';
import { TableHeaderCell } from '../../@types/tableRows';
import { SortBy } from '../../@types/filters';

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const useStyles = makeStyles<Theme>((theme) => ({
  stickyHeaderContainer: {
    position: 'fixed',
    top: APPBAR_MOBILE,
    left: '0px',
    zIndex: 1200,
    visibility: 'hidden',
    [theme.breakpoints.up('lg')]: {
      top: APPBAR_DESKTOP
    },
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    scrollbarWidth: 'none',
    MsOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0
    }
  }
}));

type Props = {
  summary: Array<TableHeaderCell>;
  sortBy: SortBy;
  onSortChange: (sortBy: SortBy) => void;
  showSummary?: boolean;
};

function TableStickyHeader({
  summary,
  sortBy,
  onSortChange,
  showSummary = true
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation(['component']);
  return (
    <TableContainer className={classes.stickyHeaderContainer}>
      <Table>
        <TableHead sx={{ fontSize: '14px' }}>
          <TableRow>
            {summary.map((header, i) => (
              <SummaryHeaderCell
                showSummary={showSummary}
                key={header.field}
                index={i}
                field={header.field}
                onSortChange={onSortChange}
                sticky={i === 0}
                canSort={header.canSort}
                active={sortBy.by == header.field}
                header={{
                  align: header.align,
                  label: t(header.label),
                  subLabel: header.subLabel
                }}
                direction={sortBy.order}
                summary={header.summary}
                minWidth={header.minWidth}
                useBorderRight={header.useBorderRight}
              />
            ))}
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
}

export default TableStickyHeader;
