import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MultiselectDefault from './MultiselectDefault';
import useFilters from '../../../hooks/useFilters';
import { MultiselectItem } from '../../../@types/filters';
import { APIStatus } from '../../../@types/APIStatus';

type Props = {
  valueSelected: Array<MultiselectItem>;
  onChangeValue: (value: Array<MultiselectItem>) => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
};

function MultiselectChannels({
  valueSelected,
  onChangeValue,
  label = 'component:channel',
  placeholder = 'component:select_channels',
  disabled = false
}: Props) {
  const { t } = useTranslation(['component']);
  const {
    filtersStatus: { sourceChannels: status },
    getSourceChannels,
    response: { sourceChannels }
  } = useFilters();

  useEffect(() => {
    if (status === APIStatus.IDLE || status === APIStatus.REJECTED) {
      getSourceChannels();
    }
  }, []);

  return (
    <MultiselectDefault
      disabled={disabled}
      label={t(label)}
      placeholder={t(placeholder)}
      valueSelected={valueSelected}
      options={sourceChannels}
      onChange={onChangeValue}
    />
  );
}

export default MultiselectChannels;
