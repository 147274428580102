import {
  InputAdornment,
  makeStyles,
  TextField,
  Theme
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles<Theme>((theme) => ({
  content: {
    width: '100%',
    backgroundColor: 'white'
  }
}));

export interface ISearchTextFieldProps {
  id: string;
  placeholder: string;
  value: string;
  disabled?: boolean;
  onSearchChange: (searchText: string) => void;
}

export function SearchTextField({
  placeholder,
  id,
  value,
  disabled = false,
  onSearchChange
}: ISearchTextFieldProps) {
  const classes = useStyles();

  return (
    <TextField
      disabled={disabled}
      size="small"
      value={value}
      onChange={(e) => onSearchChange(e.target.value)}
      id={id}
      className={classes.content}
      placeholder={placeholder}
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
          </InputAdornment>
        )
      }}
    />
  );
}
