import {
  makeStyles,
  Theme,
  Paper,
  Grid,
  FormControlLabel,
  Tooltip,
  IconButton,
  CircularProgress,
  alpha,
  Box,
  Switch
} from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';
import { Icon } from '@iconify/react';
import SelectTimePeriod from '../filters/SelectTimePeriod';
import { FilterSelect } from '../items/filters/FilterSelect';
import { usefilterBarStyles } from '../../theme/forms/FilterBarClasses';
import useOrdersTab from '../../hooks/useOrdersTab';
import { APIStatus } from '../../@types/APIStatus';

import useFilters from '../../hooks/useFilters';
import {
  CountrySelectItem,
  DateRangeFilter,
  MultiselectItem,
  SortBy
} from '../../@types/filters';

import { useCopyFilters } from '../../hooks/useCopyFilters';
import MultiselectCustomers from '../filters/multiselect/MultiselectCustomers';
import MultiselectChannels from '../filters/multiselect/MultiselectChannels';
import { SearchTextField } from '../filters/SearchTextField';
import MultiselectMandantShops from '../filters/multiselect/MultiselectMandantShops';
import SlideRange from '../filters/SlideRange';
import MultiselectOrderStatus from '../filters/multiselect/MultiselectOrderStatus';
import MultiselectOrderType from '../filters/multiselect/MultiselectOrderType';

const useStyles = makeStyles<Theme>((theme) => ({
  loadBox: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    borderRadius: theme.spacing(1),
    backgroundColor: alpha('#fff', 0.6),
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9,
    transition: 'background-color 0.3s'
  }
}));

type OrdersFilterBarProps = {
  page: number;
  rowsPerPage: number;
  sortBy: SortBy;
  setInitialPage: () => void;
  articleId?: string;
  tab?: string;
};

export function OrdersFilterBar({
  page,
  rowsPerPage,
  sortBy,
  setInitialPage,
  articleId,
  tab = ''
}: OrdersFilterBarProps) {
  const { t: translate } = useTranslation(['component']);
  const filterClasses = usefilterBarStyles();
  const classes = useStyles();

  const {
    getCountries,
    countryOrders_old: countries,
    filtersStatus: { countries: cStatus, mandantShops: mStatus },
    response: { orderListRanges }
  } = useFilters();

  const {
    ordersFilters: filterBar,
    ordersAPIStatus: state,
    updateOrders,
    cancelFetch
  } = useOrdersTab();

  const { copyUrlToClipboard, iconData } = useCopyFilters();

  const areFiltersLoaded = useMemo(
    () => cStatus === APIStatus.FULFILLED && mStatus === APIStatus.FULFILLED,
    [cStatus, mStatus]
  );

  const areFiltersLoading = useMemo(
    () => cStatus === APIStatus.PENDING || mStatus === APIStatus.PENDING,
    [cStatus, mStatus]
  );

  const isLoaded = useMemo(
    () => state === APIStatus.FULFILLED && areFiltersLoaded,
    [state, areFiltersLoaded]
  );

  const [amountState, setAmountState] = useState<Array<number>>(
    filterBar.amount
  );
  const [marginState, setMarginState] = useState<Array<number>>(
    filterBar.margin
  );
  const [timeRangeState, setTimeRangeState] = useState<DateRangeFilter>(
    filterBar.timePeriod
  );
  const [mandantShopsState, setMandantShopsState] = useState<
    Array<MultiselectItem>
  >(filterBar.mandantShops);
  const [sourceChannelsState, setSourceChannelsState] = useState<
    Array<MultiselectItem>
  >(filterBar.sourceChannels);
  const [customersState, setCustomersState] = useState<Array<MultiselectItem>>(
    filterBar.customerList
  );
  const [countryState, setCountryState] = useState<number>(filterBar.countryId);
  const [orderStatusState, setOrderStatusState] = useState<
    Array<MultiselectItem>
  >(filterBar.status);

  const [orderTypeState, setOrderTypeState] = useState<Array<MultiselectItem>>(
    filterBar.orderType
  );

  const [useDefaultTypesState, setUseDefaultTypesState] =
    useState<boolean>(true);

  const [name, setName] = useState<{
    text: string;
    triggerDebounce: boolean;
  }>({ text: filterBar.searchOrders, triggerDebounce: false });

  const debounceFilter = useMemo(
    () =>
      _debounce(
        (n: { text: string; triggerDebounce: boolean }) =>
          updateOrders(
            { ...filterBar, searchOrders: n.text },
            { skip: page * rowsPerPage, limit: rowsPerPage },
            sortBy,
            articleId
          ),
        1000
      ),
    []
  );

  useEffect(() => {
    if (name.text !== filterBar.searchOrders && name.triggerDebounce) {
      debounceFilter(name);
    }
  }, [name, debounceFilter]);

  useEffect(() => {
    if (areFiltersLoaded) {
      updateOrders(
        {
          timePeriod: timeRangeState,
          amount: amountState,
          margin: marginState,
          countryId: countryState,
          searchOrders: name.text,
          status: orderStatusState,
          sourceChannels: sourceChannelsState,
          customerList: customersState,
          mandantShops: mandantShopsState,
          orderType: orderTypeState
        },
        { skip: page * rowsPerPage, limit: rowsPerPage },
        sortBy,
        articleId
      );
    }
    return () => {
      cancelFetch();
    };
  }, [
    timeRangeState,
    countryState,
    useDefaultTypesState,
    orderStatusState,
    sourceChannelsState,
    customersState,
    mandantShopsState,
    orderTypeState,
    page,
    rowsPerPage,
    sortBy,
    areFiltersLoaded
  ]);

  useEffect(() => {
    if (cStatus === APIStatus.IDLE || cStatus === APIStatus.REJECTED) {
      getCountries();
    }
  }, []);

  useEffect(() => {
    if (useDefaultTypesState) {
      setOrderTypeState([
        { name: translate('filter:sales_order'), id: 1 },
        { name: translate('filter:credit_note'), id: 4 },
        { name: translate('filter:warranty_order'), id: 5 },
        { name: translate('filter:repair_order'), id: 6 }
      ]);
    }
  }, [useDefaultTypesState]);

  return (
    <Paper
      elevation={5}
      className={filterClasses.root}
      sx={{ position: 'relative' }}
    >
      {areFiltersLoading && (
        <Box className={classes.loadBox}>
          <CircularProgress />
        </Box>
      )}
      <Grid container spacing={2} sx={{ padding: '16px', paddingTop: '0px' }}>
        <Grid item xs={12} sm={8}>
          <SearchTextField
            id="search_order_ids"
            placeholder={translate('filter:search_order_id')}
            value={name.text}
            onSearchChange={(searchOrders: string) =>
              setName({ text: searchOrders.trimStart(), triggerDebounce: true })
            }
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <SelectTimePeriod
            disabled={!isLoaded}
            startFilter={timeRangeState.startFilter}
            endFilter={timeRangeState.endFilter}
            onChangePeriod={setTimeRangeState}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          {/* Mandant-shop-account */}
          <MultiselectMandantShops
            disabled={!isLoaded}
            valueSelected={mandantShopsState}
            onChangeValue={setMandantShopsState}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          {/*  Sources (Channels)  */}
          <MultiselectChannels
            disabled={!isLoaded}
            valueSelected={sourceChannelsState}
            onChangeValue={setSourceChannelsState}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          {/* Customers */}
          <MultiselectCustomers
            disabled={!isLoaded}
            valueSelected={customersState}
            onChangeValue={setCustomersState}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FilterSelect
            disabled={!isLoaded}
            selected={
              countries.findIndex(
                (c: CountrySelectItem) => c.countryId === countryState
              ) + 1
            }
            title={translate('filter:country')}
            labels={[
              translate('filter:all_countries'),
              ...countries.map((c) => c.countryName)
            ]}
            onChangeSelected={(option) => {
              const cId = option === 0 ? 0 : countries[option - 1].countryId;
              setCountryState(cId);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          {/* Order Status */}
          <MultiselectOrderStatus
            disabled={!isLoaded}
            onChangeValue={setOrderStatusState}
            valueSelected={orderStatusState}
          />
        </Grid>

        <Grid container spacing={2} item xs={12} sm={6} alignItems="center">
          <Grid item xs={12} sm={8}>
            {/* Order type */}
            <MultiselectOrderType
              disabled={!isLoaded || useDefaultTypesState}
              onChangeValue={setOrderTypeState}
              valueSelected={orderTypeState}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={useDefaultTypesState}
                  onChange={(e, checked) => {
                    setUseDefaultTypesState(checked);
                  }}
                  inputProps={{ 'aria-label': 'Order types' }}
                />
              }
              label={translate('filter:use_default_order_types')}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2} alignItems="center">
          {/* <Grid item xs={12} sm={6} md={4}>
            <SlideRange
              disabled={true}
              step={100}
              type="euro"
              label={translate('component:amount')}
              min={orderListRanges.minRevenue}
              max={orderListRanges.maxRevenue}
              range={amountState}
              onRangeChange={(value) => {
                if (isLoaded) {
                  setAmountState(value);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SlideRange
              disabled={true}
              step={1}
              type="percent"
              label={translate('component:margin')}
              min={orderListRanges.minMargin}
              max={orderListRanges.maxMargin}
              range={marginState}
              onRangeChange={(value) => {
                if (isLoaded) {
                  setMarginState(value);
                }
              }}
            />
          </Grid> */}

          <Grid container item xs={12} justifyContent="flex-end">
            <Tooltip title={iconData.tooltip}>
              <IconButton
                color="primary"
                aria-label="copy url"
                onClick={() => {
                  copyUrlToClipboard(filterBar, {
                    page,
                    rowsPerPage,
                    by: sortBy.by,
                    order: sortBy.order
                  });
                }}
              >
                <Icon icon={iconData.icon} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
